<template>
<!-- 开展业务 -->
  <div>
    <div class="banner" id="hospital_banner1" :style="{backgroundImage:('url('+banner+')')}">
      <!-- <img
        :src="banner"
        :alt="$t('indexnav.Our Business')"
      /> -->
      <span>{{$t('indexnav.Our Business')}}<i :style="{
            display: this.$store.state.nowlanguage === 'en' ? 'none' : '',
          }">Our Business</i></span>
    </div>

    <section class="ab-item1">
      <!-- <a href="/">{{$t('indexnav.Home')}}</a> > <a>{{$t('indexnav.Our Business')}}</a> -->
      <bread-crumb />
      </section>

    <article>
        <div class="loading_box" v-if="showLoading"></div>
       <div v-for="item in dataList" :key="item.id">
        <div class="gsywImgContainer">
          <img
            :src="item.imgUrl"
            :alt="item.title"
            class="wenzhangImg"
          />
        </div>
        <div>
          <span>{{filterLang(item, 'Title')}}</span>
         {{filterLang(item, 'Content')}}
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import {yewuDataList} from "@/api/kaizhanyewu"
import { filterLang } from "@/utils/LangChange";
import {getAction} from '@/api/manage'
import { PageType } from '@/utils/constant';

export default {
  data () {
    return {
        dataList:[],
        showLoading:true,
      banner: ''
    }
  },
  methods:{
    filterLang
  },
  created(){
    let self = this;

    // 获取开展业务banner
    getAction("/jeecg-system/common/frontend/advertise/active_list", {typeCode: PageType.KAIZHANYEWU}).then(res => {
      if (res.result.records instanceof Array && res.result.records.length > 0) {
         if (res.result.records[0].isMultipatLanguge!=='0') {
          if (this.$store.state.nowlanguage == "zh")
            self.banner = res.result.records[0].sampleImg;
          if (this.$store.state.nowlanguage == "tr")
            self.banner = res.result.records[0].tranditionalImg
          if (this.$store.state.nowlanguage == "en")
            self.banner = res.result.records[0].enImg;
        }else{
        self.banner = res.result.records[0].sampleImg
        }
      }
    });


    // 获取公司开展业务数据
    yewuDataList().then((res)=>{
      self.showLoading=false
      self.dataList=res.result
    })
  }
};
</script>

<style scoped lang='less'>
@media screen and (min-width: 1024px) {
   .wenzhangImg{
      max-height:170px;
    }
}
@media screen and (min-width: 770px) {
   .wenzhangImg{
           max-height:170px;
    }
}
header + a img {
  max-width: 100%;
  width: 100%;
}
.banner{
      width: 100%;
    position: relative;
}
.banner img {
  width: 100%;
  max-width: 100vw;
  display: block;
}
.banner span{
      width: 100%;
    height: 100%;
    font-size: 3rem;
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.banner span i {
    font-size: 2rem;
    font-style: normal;
    opacity: .5;
}

section {
  margin: 1.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

.ab-item1 {
  padding: 0 0 1.5em 0;
  border-bottom: 1px solid #d2d2d2;
  font-size: 1.5rem;
  color: #595757;
}

article {
  margin: 3.5em auto 0 auto;
  width: 100%;
  max-width: 1200px;
}

article div {
  display: flex;
  margin-bottom: 2em;
}

article div div:nth-child(1) {
  padding-right: 3em;
}

article div div:nth-child(1) img {
  width: 85%;
}

article div div:nth-child(2) {
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  color: #838181;
  line-height: 1.8em;
  flex: 1;
}

article div div:nth-child(2) span {
  font-size: 1.3em;
  font-weight: 600;
  color: #165da3;
  margin-bottom: 0.8em;
}

@media screen and (min-width: 768px) {
    .gsywImgContainer {
      max-height:100% ;
        >img{
          min-width:289px ;
        }
    }
}

@media screen and (max-width: 1200px) {
  .ab-item1 {
    font-size: 1.2em;
    padding: 0 1.5em 1.5em 1.5em;
  }

  article {
    padding: 0 1.5em 1.5em 1.5em;
  }
}

@media screen and (max-width: 768px) {
  article div {
    flex-direction: column;
  }
article div div:nth-child(1) {
  padding-right: 0em;
}
  article div div:nth-child(1) img {
    width: 100%;
  }
}
</style>
